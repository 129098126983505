#root {
    height: 100%;
}
.pages-container {
    height: 100%;
    min-height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
}
.pages-bar-left {
    background: blue;
    width: 60px;
    min-width: 60px;
}
.pages-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.pages-content-top {
    height: 50px;
    min-height: 50px;
    /* margin-left: -60px; */

    /* background-color: #f3f4f5 !important; */
}
.notification {
    position: absolute;
    top: 15px;
    right: 21px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
}
.pages-content-top menu {
    border-top: none;
    border-left: none;
    border-right: none;
}
.pages-content-body {
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    background-color: #f3f4f5 !important;
}
.pages-content-footer {
    background: #d4d4d5;
    height: 37px;
    min-height: 37px;
}

.scroll-menu {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: calc(100vh - 98px);
    scroll-behavior: smooth;
}
