.car-lable {
    width: 40px;
}
.car-outer {
    display: table;
    height: 40px;
    overflow: hidden;
    text-align: center;
    width: 210px;
}
.car-inner {
    display: table-cell;
    vertical-align: left;
    line-height: 13px;
}
.car-text {
    color:black;
    font-size: 11px;
    text-align: center;
}
.point-font {
    font-family: Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px;
}

.point-date {
    display: table;
    height: 40px;
    overflow: hidden;
    text-align: right;
    font-family: Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 12px;
}

.progress-visual {
    font-size: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.progress-visual__block {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.progress-visual__addresses {
    display: flex;
}

.progress-visual__address {
    width: 50%;
}

.progress-visual__address_loading {
    padding-right: 50px;
    text-align: end;
}

.progress-visual__address_unloading {
    padding-left: 50px;
    text-align: start;
}

.vehicle-driver-data {
    font-size: 12px;
}

.vehicle-driver-data_pick-up {
    padding-right: 50px;
    text-align: end;
    width: 50%;
}

.vehicle-driver-data_delivery {
    padding-left: 50px;
    text-align: start;
    width: 50%;
}

.vehicle-driver-data_pick-up-delivery {
    text-align: center;
    width: 100%;
}
