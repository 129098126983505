.button-complete-wiz.ui.button.dropdown {
    border-left: 1px solid white !important;
    padding-top: 5px !important;
}

.icon-validation-negative {
    font-size: 27px;
    color: red;
    margin-left: 10px;
}

input.cargoCost {
    width: inherit;
    /* width: 90px; */
    text-align: right;
}

@media screen and (max-width: 1452pх) {
    input.cargoCost {
        width: 90px !important;
    }
}

.column.editor-rightcol {
    padding-left: 0 !important;
}

@media screen and (min-width: 1600px) {
    .column.editor-rightcol {
        width: 72% !important;
    }
}
.bottom-alert {
    display: absolute;
    bottom: 0;
}
/* .page-bgn {
    background-image: url('/img/bgn6.png') !important;
    background-size: cover !important;
} */

.table-unloads td {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	padding-right: 0 !important;
}

.table-unloads tr {
	color: rgba(0,0,0,.4);
}