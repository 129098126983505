.ui.steps.steps-grey a.link {
    background: #f3f4f5;
}
.ui.steps.steps-grey a.link.active {
    background: white;
}
.ui.steps.steps-grey a.link.active:hover {
    background: white;
}
.ui.steps .step>.icon {
    /* set margin under icons */
    margin-bottom: 3px;
}
.ui.fluid.dropdown > .dropdown.icon {
    padding: 7px;
}
.step-list > .item {
    line-height: 18px !important;
}
.as-link {
    font-size: 13px;
    cursor: pointer;
    color: #4183c4;
}
.as-link:hover {
    color: #1e70bf;
}
.order-edit-step-body {
    height: calc(100vh - 80px); 
    margin-top: 5px;
    min-height: 40px;
}