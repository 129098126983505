.checkbox-label-right label {
    float: left !important;
    margin: 0 5px 0;
}

/* .point-form.ui.divided.list > .item:first-child {
    border-top: 1px solid rgba(34,36,38,.15);
} */

a.label-add.ui.basic.label {
    background-color: rgba(0, 128, 0, 0) !important;
    border: 1px dashed grey !important;
    color: inherit !important;
    text-align: center;
    display: inline-block;
    width: 50px;
}
.point-card-form.ui.placeholder.segment {
    min-height: 287px;
    justify-content: flex-start;
}
.point-card-form.ui.placeholder.segment .field {
    max-width: inherit;
}
.point-card-form.ui.placeholder.segment .field textarea {
    max-width: inherit;
}

.point-form-services {
    max-height: 206px;
    overflow-y: auto;
    overflow-x: hidden;
}
.point-form-services.ui.small.form label{
    font-size:.92857143em;
}

.point-card-tabs.ui.pointing.menu a.active.item {
    background: white;
}
.point-card-tabs.ui.pointing.menu a.active.item:after {
    background: white;
    top: 103%
}
.point-card-tabs.ui.pointing.menu a.item:hover {
    background: rgba(0,0,0,.01);
}
.point-card-tabs.ui.pointing.menu a.item {
    background: rgba(0,0,0,.05);
}
.point-card-tabs.ui.pointing.menu a.item:after {
    background: rgba(0,0,0,.05);
    top: 103%
}

.totalsStyleTop {
    text-align: center;
    border-bottom: 1px solid rgba(34,36,38,.20);
    margin-bottom: 25px;
    padding-bottom: 9px;
    box-shadow: -3px 2px 2px 0 rgba(34,36,38,.15);
    /* background: linear-gradient(0deg, #eee, white 70%); */
}

.totalsStyleBottom {
    text-align: center;
    border-top: 1px solid rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(34,36,38,.20);
    box-shadow: -3px 2px 2px 0 rgba(34,36,38,.15);
    margin-top: 10px;
    margin-bottom: 5px;
    padding-bottom: 3px;
    padding-top: 3px;
    background: #eee;
}

.bottomShadow {
    box-shadow: -3px 2px 2px 0 rgba(34,36,38,.15);
}
.point-card-shell-header-menu.ui.dropdown {
    min-height: inherit;
    line-height: inherit !important;
    padding-top: 0 !important;
    padding-right: 0;
    padding-bottom: 0 !important;
    padding-left: 0;
    z-index: 101;
}
.point-card-shell-header-menu.ui.dropdown > .menu > .item > .text {
    font-size: 14px !important;
}
.point-card-shell-header-menu.ui.dropdown > .menu > .item > i {
    font-size: 16px !important;
}
.point-card-shell-header-menu.ui.dropdown > .menu {
    margin-top: 5px !important;
    margin-right: -2px !important;
}