/* .ui.table tr:first-child td {
    
} */
.border-top {
    border-top: 1px solid rgba(34,36,38,.1) !important;
}
.app-table.ui.table tr {
    font-size: inherit;
}
.app-table.ui.table tr.active {
    background-color: #eeeeee !important;
}
.app-table > thead > tr > th {
    padding-top: 11px !important;
}

/* .app-table tr:first-child td {
    border-top: 1px solid rgba(34,36,38,.1) !important;
} */
/* .app-table.ui.selectable.table tbody tr:hover {
    cursor: pointer;
} */

.app-table-wrapper {
    margin-top: -2px; 
    /* height: calc(100vh - 149px); */
    overflow: hidden;
    overflow-y: auto;
    /* border-top: 2px solid rgba(34,36,38,.1) !important; */
    /* border-bottom: 1px solid rgba(34,36,38,.1) !important; */
}
.app-table-scroll {
    overflow: hidden;
    overflow-y: scroll;
}
.app-table-header {
    z-index: 2;
    /* border-bottom: 0px; */
    /* background: none !important; */
}
.app-table-header-row {
    position: relative;
}
tr.app-table-header-row > th:first-child, .app-table-body-row > td:first-child {
    padding-left: 10px !important;
}
.app-table-header-row > th {
    position: -webkit-sticky; 
    position: sticky;
    top: 0px;
    z-index: 1;
    /* background: #eeeeee !important; */
    background: white !important;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15) !important;
    /* border-bottom: 1px solid rgba(34,36,38,.15); */
    border-bottom: 1px solid rgba(34,36,38,.15) !important;
    border-top: 1px solid rgba(34,36,38,.15) !important;
}
.app-table-action {
    font-size: 1.3em;
    padding-right: 7px !important;
}
.table-footer {
    font-size: 12px;
    font-weight: bold;
}

.app-table-header-bgn {
    background: linear-gradient(to top, rgba(249,250,251,0) 0%,rgba(249,250,251,1) 100%);
}
