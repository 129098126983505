.table-banners {
    //width: calc(100vw);
}
.table-banners-header-fixed__title-actions {
    position: sticky!important;
    top: 0;
    right: 0;
    border-right: 1px solid rgba(34, 36, 38, .1);
    z-index: 10;
}

.table-banners-action-sticky {
    position: sticky;
    right: 0;
    top: 1px;
    z-index: 5;
    background-color: #fff;
    border-right: 1px solid rgba(34,36,38,.1);
}

.banner-form_counter, .banner-form_url {
    display: flex;
    align-items: center;
}
.banner-form_counter span {
    padding-left: 24px;
    max-width: 10%;
    min-width: 10%;
}

.banner-form_url .ui.input{
    width: 70% !important;
}

.banner-form_counter label, .banner-form_url label {
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
    max-width: 20%;
    min-width: 20%;
}

.banner-form_url label {
    width: 18% !important;
}
