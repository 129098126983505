.dropzone {
    width: 100% !important;
    position: relative;
    height: 200px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
    padding: 80px;
    text-align: center;
    margin-top: 0;
}