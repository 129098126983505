.light-gray.ui.button {
    background-color: #b5b5b5 !important;
    color: white !important;
}

.light-gray.ui.button:hover {
    background-color: gray !important;
}

.waybill-button-highlight {
    background-color: #ffc10724 !important;
}

.waybill-button-highlight:hover {
    background-color: #ffc10770 !important;
}

.waybills-toolbar {
    min-height: 47px !important;
    margin-top: -1px !important;
}

.waybills-toolbar i {
    color: #106ebe;
}

.waybills-toolbar-dropdown {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.waybills-toolbar-dropdown-menu>.item>a {
    color: black !important;
}

.waybills-toolbar-dropdown-menu>.item>.ui.header.new-option>i.icon {
    display: table-cell !important;
    color: rgb(104, 104, 104);
    /* font-size: x-large !important; */
}

.waybills-toolbar .ui.vertical.divider:before {
    top: -70%;
    height: 70%;
}

.waybills-toolbar .ui.vertical.divider::after {
    bottom: inherit;
    height: 70% !important;
}

.waybills-toolbar>.menu>a.disabled.item {
    display: none;
}


.ui.sortable.table thead th.sorted {
    background: #f9fafb !important;
}
