.c-time-slot-list {
    max-height: calc(30vh);
    overflow: auto;
}

.c-time-slot-list__item_time {
  cursor:pointer;
}
.c-time-slot-list__item_time:hover {
  background-color: #f0f0f0!important;
}
.c-time-slot-list__item_time_selected {
  background-color:#2a87d0!important;
  color: white;
}

.c-time-slot-list__item_time_selected:hover {
  background-color: #1d5d90!important;
  color: white;
}

.c-time-slot__popup {
  max-width: 500px!important;
}
.c-time-slot.c-time-slot_input.error input{
  background: #fff6f6;
  color: #9f3a38;
}

.c-time-slot__clear {
  position: absolute;
  right: 5%;
  bottom: 35%;
  font-size: 14px;
  cursor: pointer!important;
  color: #999;
  transition: 0.5s;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  padding: .38em .38em .38em!important;
}
